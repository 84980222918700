import Vue from 'vue'
import App from './App.vue'
import LabLanding from './components/LabLanding'
import ExamLanding from './components/ExamLanding'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouter from "vue-router";
import VueGtag from "vue-gtag";
import VueGtm from '@gtm-support/vue2-gtm';

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    clientToken: 'puba05ee57d80ae3da04ec505441a87238f',
    applicationId: '77944791-0571-47de-be4f-42fbbe65e619',
    site: 'datadoghq.com',
    service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
    env: 'production',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    trackViewsManually: true,
    defaultPrivacyLevel: 'allow'
});

datadogRum.startSessionReplayRecording();

Vue.use(VueGtm, {
    id: 'GTM-TT93VRS',
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: '7f26c2726c', // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  });
  

Vue.use(VueGtag, {
    config: { id: "UA-103562009-1" }
});

Vue.use(VueRouter);

const routes = [{
        path: "/img",
        component: ExamLanding,
        // props: (route) => ({ swap: route.query.swap })
    },
    {
        path: "/lab",
        component: LabLanding,
        // props: (route) => ({ swap: route.query.swap })
    }
];
const router = new VueRouter({
    routes
});

import { VueReCaptcha } from 'vue-recaptcha-v3'

// For more options see below
Vue.use(VueReCaptcha, { siteKey: '6LfbHKAeAAAAAPiSzRAQHKk04aMtWUKmfPZjmJWX' })

import 'materialize-css/dist/css/materialize.min.css'
import 'material-design-icons/iconfont/material-icons.css'

import { rutFilter, rutInputDirective, rutValidator } from 'vue-dni';
import VeeValidate, { Validator } from 'vee-validate'

Vue.use(VeeValidate);
//Vue.validator('rutValidator', rutValidator);
Validator.extend('rut', rutValidator);
Vue.directive('rut', rutInputDirective);
Vue.filter('rutFilter', rutFilter);

Vue.use(VueAxios, axios)

Vue.config.productionTip = false
Vue.prototype.$datadogRum = datadogRum

new Vue({
    render: h => h(App),
    router
}).$mount('#app')