<template>
  <div>
    <div v-show="error" class="col s12 consultando center-align">
      <div v-if="errorMsg === 'No hay resultados'">
        <div class="row">
          <div class="col s12">
            <span class="card-title"
              >No existen resultados para esa orden de pago y documento.</span
            >
          </div>
        </div>
        <div class="row">
          <div class="col s12 footer-content">
            <button class="custom-btn" @click="CerrarErrorYVolver">
              Volver
            </button>
          </div>
        </div>
      </div>
      <div v-if="errorMsg !== 'No hay resultados'" class="card teal">
        <div class="card-content white-text">
          <span class="card-title">Se ha producido un error</span>
          <p>{{ errorMsg }}</p>
        </div>
        <div class="card-action">
          <a
            class="
              waves-effect waves-light
              btn-small
              pulse
              yellow
              lighten-2
              green-text
            "
            @click="CerrarErrorYVolver"
            >Reintente por favor</a
          >
        </div>
      </div>
    </div>

    <div v-show="!error">
      <form v-show="searching" @submit.prevent="doSubmit">
        <div class="row">
          <div class="col s12 m5 input-field hoverable">
            <label class="active" for="tipo_documento_select"
              >Tipo de Documento</label
            >
            <select :class="[{'browser-default': isMobile}]"
              v-model="tipo_documento"
              id="tipo_documento_select"
              name="tipo_documento_select"
            >
              <option value="" disabled selected>
                Elija tipo de documento
              </option>
              <option
                v-for="(option, key) in tipos_documento"
                :key="key"
                :value="key"
              >
                {{ option }}
              </option>
            </select>
          </div>

          <div
            v-show="tipo_documento === 0"
            class="col s12 m7 input-field hoverable"
          >
            <label for="numDocRut">Número de Documento</label>
            <input
              ref="rut"
              id="numDocRut"
              name="numDocRut"
              type="text"
              v-model="numberDoc"
              required
              v-rut:live
              v-validate="tipo_documento === 0 ? 'required|rut' : ''"
            />
            <span v-show="errors.has('numDocRut')" class="red-text"
              ><b>Rut no es válido</b></span
            >
          </div>

          <div
            v-show="tipo_documento === 1"
            class="col s12 m7 input-field hoverable"
          >
            <label for="numDocPasaporte">Número de Documento</label>
            <input
              id="numDocPasaporte"
              name="numDocPasaporte"
              type="text"
              v-model="numberDoc"
              required
              v-validate="tipo_documento === 1 ? 'required' : ''"
            />
          </div>

          <div class="col s12">
            <div class="row no-padding-x valign-wrapper hoverable">
              <div class="col s12 m5 input-field">
                <label for="numberOrden">Número de Orden de Pago</label>
                <input
                  id="numberOrden"
                  name="numberOrden"
                  type="number"
                  v-model="numberOrden"
                  required
                  v-validate="'required'"
                />
                <span v-show="errors.has('numberOrden')" class="red-text"
                  ><b>Este dato es obligatorio</b></span
                >
              </div>

              <div class="col s12 m7">
                <comprobante-pago modo="examen" />
              </div>
            </div>
          </div>

          <div class="col s12 center-align">
            <span id="alertaMsj" class="spanAlert" style="visibility: visible"
              >(*) La visualización de estos resultados
              <span style="font-weight: bold">NO</span> están disponibles para
              <span style="font-weight: bold"
                >Clínica RedSalud Valparaíso ni
                Clínica RedSalud Rancagua.</span
              ></span
            >
          </div>


          <div class="col s12 footer-content center-align" v-if="!loading">
            <div class="row">
              <div class="col s12">
                <br/>
                <h5>Ver resultados para</h5>
              </div>
              <div class="col s6" v-if="!loading">
                <a
                  id="submitButton"
                  :class="[
                    { disabled: !formularioOK, pulse: formularioOK },
                    'waves-effect',
                    'waves-light',
                    'btn',
                    'custom-btn',
                  ]"
                  @click="setAltTarget"
                >
                  Clínica Magallanes / Convenios
                </a>
              </div>
              <div class="col s6" v-if="!loading">
                <a
                  id="submitButton"
                  :class="[
                    { disabled: !formularioOK, pulse: formularioOK },
                    'waves-effect',
                    'waves-light',
                    'btn',
                    'custom-btn',
                  ]"
                  @click="setRegularTarget"
                >
                  Resto de la red
                </a>
              </div>
            </div>
          </div>

          <div
            class="col s12 m6 offset-m6 footer-content input-field"
            v-if="!loading && swapServer"
          >
            <span v-if="!switchServer" @click="switchServer = true"
              >Servidor: {{ target_server }}</span
            >
            <select :class="[{'browser-default': isMobile}]"
              v-if="switchServer"
              v-model="target_server"
              id="target_server_select"
              style="display: block"
              @input="switchServer = false"
            >
              <option value="" disabled selected>Elija servidor destino</option>
              <option
                v-for="(option, key) in target_servers"
                :key="key"
                :value="option"
              >
                Servidor {{ option }}
              </option>
            </select>
            <label class="active" v-if="switchServer" for="target_server_select"
              >Elija Servidor</label
            >
          </div>

          <div v-if="loading" class="col s12 consultando">
            <span>Consultando...</span>
            <div class="progress">
              <div class="indeterminate"></div>
            </div>
          </div>
        </div>
      </form>
      <div v-show="!searching">
        <div class="row">
          <div class="col s12 pacienteInfo">
            <label for="paciente">Paciente</label><br />

            <div v-if="info.patient" class="row">
              <div class="col s12 m6">
                {{ info.patient.FullName }}
              </div>
              <div class="col s12 m6" v-show="DocumentType === 'RUT'">
                {{ DocumentType }}:
                <span>{{ info.patient.DocumentNumber | rutFilter }}</span>
              </div>
              <div class="col s12 m6" v-show="DocumentType !== 'RUT'">
                {{ DocumentType }}:
                <span>{{ info.patient.DocumentNumber }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row all" v-if="info.all && info.all.length === 0">
          <div class="col s12 text-center">No hay resultados</div>
        </div>
        <div class="row all-header" v-if="info.all && info.all.length > 0">
          <div class="col show-on-small s10 hide-on-med-and-up">
            Descripción
          </div>
          <div class="col hide-on-small-only m3">Fecha</div>
          <div class="col hide-on-small-only m7">Descripción</div>
          <div class="col s2">Resultado</div>
        </div>
        <div class="row all" v-for="(item, key) in info.all" :key="key">
          <div class="col show-on-small s10 hide-on-med-and-up">
            {{ item[5].length > 0 ? item[5] : "(sin descripción)" }}<br />{{
              item[4]
            }}
          </div>
          <div class="col hide-on-small-only m3">{{ item[4] }}</div>
          <div class="col hide-on-small-only m7">
            {{ item[5].length > 0 ? item[5] : "(sin descripción)" }}
          </div>
          <div class="col s2">
            <a
              v-show="item[1].length > 0 && item.length === 6"
              class="waves-effect waves-light btn-flat"
              @click="unlockDocument(key)"
              ><i class="material-icons green-text">lock_open</i></a
            >
            <div
              class="progress"
              v-show="item.length === 7 && item[6].length === 0"
            >
              <div class="indeterminate"></div>
            </div>
            <a
              v-show="item.length === 7 && item[6].length > 0"
              class="waves-effect waves-light btn-floating pulse"
              @click="loadDocument(key)"
              ><i class="material-icons white-text">open_in_new</i></a
            >
          </div>
        </div>

        <div class="row">
          <div class="col s12 footer-content">
            <button class="custom-btn" @click="Volver">Volver</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import M from "materialize-css";
import ComprobantePago from "./ComprobantePago";

const APIKEY = "UMVyMXPIj0sz2NAFiTVhtc8q58D023Mb";

export default {
  name: "ExamLanding",
  components: {
    ComprobantePago,
  },
  data() {
    return {
      switchServer: false,
      tipos_documento: ["Cédula de identidad", "Pasaporte"],
      tipo_documento: 0,
      target_servers: ["A", "B", "C", "D"],
      target_server: "C",
      env: "",
      error: false,
      errorMsg: "",
      loading: false,
      searching: true,
      numberOrden: process.env.VUE_APP_DEFAULT_NUMBER_ORDEN,
      numberDoc: process.env.VUE_APP_DEFAULT_NUMBER_DOC,
      rutFeedback: "",
      submitted: false,
      info_raw: [],
      target: process.env.VUE_APP_API_TARGET,
      target_alternative: process.env.VUE_APP_API_ALT_TARGET
    };
  },
  computed: {
    info () {
      if (this.selectedTarget == this.target_alternative) {
        if (Array.isArray(this.info_raw) && this.info_raw.length == 0) {
          return {}
        }
        return this.info_raw;
      }
      return {
        patient: {
          DocumentNumber: this.info_raw[0]?.dni,
          FullName: this.info_raw[0]?.patient_name.replace(/\^/g, ' ').trim()
        },
        all: this.info_raw.map(item => {
          return [
            0,
            item?.token,
            2,
            3,
            item?.study_date,
            item?.study_description,
            item?.url
          ]
        }).sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b[4]) - new Date(a[4]);
        })
      }
    },
    swapServer() {
      return "swap" in this.$route.query && this.$route.query.swap.length > 0;
    },
    DocumentType() {
      var regexp = /^(\d{1,3}(?:\.*\d{1,3}){2}-[\dkK])$/
      if (regexp.test(this?.info?.patient?.DocumentNumber)) {
        return "RUT";
      }
      return "Pasaporte";
    },
    formularioOK() {
      return (
        this.errors.items.length === 0 &&
        this.numberDoc.length * this.numberOrden.length > 0
      );
    },
  },
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha("login");

      // Do stuff with the received token.
      return token && token.length > 0;
    },
    accessGranted: function (info, target) {
      // buscar si accession number viene en datos
      if (this.selectedTarget == this.target_alternative) {
        return info.all.filter(item => item[1] == target).length > 0
      } else if (this.selectedTarget == this.target) {
        return info.filter(item => item.accession_no == target).length > 0
      }

      return false
    },
    setRegularTarget: function () {
      this.selectedTarget = this.target;
      this.doSubmit();
    },
    setAltTarget: function () {
      this.selectedTarget = this.target_alternative;
      this.doSubmit();
    },
    doSubmit: function () {
      if (!this.recaptcha()) {
        return;
      }

      this.loading = true;
      this.info_raw = [];
      this.error = false;
      this.errorMsg = "";

      this.$gtag.pageview({
        page_path: "/examenes/buscar-" + ((this.selectedTarget == this.target_alternative) ? 'philips' : 'toth')
      });
      this.$datadogRum.startView({
        name: "/examenes/buscar-" + ((this.selectedTarget == this.target_alternative) ? 'philips' : 'toth'),
        service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
        version: process.env.VUE_APP_VERSION
      })

      this.$gtag.event("items_search", {
        event_category: "examenes",
        event_label: "request-" + ((this.selectedTarget == this.target_alternative) ? 'philips' : 'toth'),
        value: 1,
      });

      axios
        .post(
          `${this.selectedTarget}/img`,
          {
            num_doc: this.numberDoc,
            tipo_doc: this.tipo_documento + "",
            magic_number: this.numberOrden,
            target_host: this.swapServer ? this.target_server : undefined,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "dni": this.numberDoc,
              "magic_number": this.numberOrden,
              "tipo_doc": this.tipo_documento + "",
              ... process.env.NODE_ENV === 'production' && { "x-apikey": APIKEY }
            },
            validateStatus: function (status) {
              return status != "429"; // Resolve only if the status code is less than 500
            },
          }
        )
        .then((response) => {
          this.loading = false;
          this.$emit("toggleShowSelectTipoExamen", false);
          this.$emit("toggleTitle", "Resultados de exámenes de imágenes");

          if (response.status == 204 || response.status == 404 || "msg" in response.data || !this.accessGranted(response.data, this.numberOrden)) {
            this.info_raw = [];
            this.error = true;
            this.errorMsg = "No hay resultados";
            this.$gtag.pageview({ page_path: "/examenes/sin-resultados" });
            this.$datadogRum.startView({
              name: "/examenes/sin-resultados",
              service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
              version: process.env.VUE_APP_VERSION
            })
          } else {
            this.searching = false;
            this.info_raw = response.data;
            this.$gtag.pageview({ page_path: "/examenes/listado" });
            this.$datadogRum.startView({
              name: "/examenes/listado",
              service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
              version: process.env.VUE_APP_VERSION
            })
          }
        })
        .catch((error) => {
          this.$gtag.pageview({ page_path: "/examenes/error" });
          this.$datadogRum.startView({
            name: "/examenes/error",
            service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
            version: process.env.VUE_APP_VERSION
          })

          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            console.log(error.message);
            this.loading = false;
            this.error = true;
            this.errorMsg = error.message;
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
    loadDocument: function (key) {
      this.$gtag.event("item_opened", {
        event_category: "examenes",
        event_label: "item_open_request",
        value: 1,
      });
      this.$gtag.pageview({ page_path: "/examenes/ver" });
      this.$datadogRum.startView({
        name: "/examenes/ver",
        service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
        version: process.env.VUE_APP_VERSION
      })
      window.open(this?.info?.all[key][6]);
    },
    unlockDocument: function (key) {
      // retrieve url and display it
      if (this.info.all[key].length === 6) {
        this.info.all[key].push("");
      }

      this.$gtag.pageview({ page_path: "/examenes/cargar" });
      this.$datadogRum.startView({
        name: "/examenes/cargar",
        service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
        version: process.env.VUE_APP_VERSION
      })

      axios
        .post(
          `${this.selectedTarget}/obfuscate`,
          {
            num_doc: this.numberDoc,
            tipo_doc: this.tipo_documento + "",
            magic_number: this?.info?.all[key][1],
            target_host: this.swapServer ? this.target_server : undefined,
          },
          {
            headers: {
              "content-type": "application/json",
              "x-apikey": process.env.NODE_ENV === 'production' ? APIKEY : undefined
            },
            validateStatus: function (status) {
              return status != "429"; // Resolve only if the status code is less than 500
            },
          }
        )
        .then((response) => {
          if (response.status == 204 || response.status == 404 || "msg" in response.data) {

            if (this.info.all[key].length === 7) {
              this.info.all[key].pop();
            }

            M.toast({
              html: "No es posible recuperar examen",
              classes: "rounded",
            });

          } else {
            this.$gtag.event("item_unlocked", {
              event_category: "examenes",
              event_label: "success",
              value: 1,
            });

            this.$set(this.info.all[key], 6, response.data.url);
            M.toast({
              html: "Ya puede acceder al examen",
              classes: "rounded"
            });
          }
        })
        .catch((error) => {
          this.$gtag.event("item_unlocked", {
            event_category: "examenes",
            event_label: "failure",
            value: 1,
          });

          if (this.info.all[key].length === 7) {
            this.info.all[key].pop();
          }

          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // console.log(error.request);
            // console.log(error.message);
            // this wont work!
            // https://github.com/axios/axios/issues/960
            console.log(error.response);
            this.loading = false;
            M.toast({
              html: "Reintente abrir examen por favor",
              classes: "rounded",
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
    CerrarErrorYVolver() {
      this.error = false;
      this.Volver();
    },
    Volver() {
      this.info_raw = [];
      this.searching = true;
      this.$emit("toggleShowSelectTipoExamen", true);
      this.$emit("toggleTitle", "Ver mis exámenes");
      M.updateTextFields();
    },
  },
  mounted() {
    M.AutoInit();
    M.updateTextFields();
    this.env = process.env.NODE_ENV;
  },
  watch: {
    tipo_documento(newVal) {
      if (newVal === 1) {
        /*
         * hemos elegido pasaporte,
         * sacar todo formato de numberDoc
         */
        this.numberDoc = this.numberDoc.replace(/-|\.|,/g, "");
      } else {
        setTimeout(() => {
          this.$refs.rut.focus();
          this.numberDoc = this.$options.filters.rutFilter(this.numberDoc);
        }, 10);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.all:nth-child(odd) {
  background-color: #e7e9e5;
}
.all:nth-child(even) {
  background-color: #f2fff2;
}
.all,
.all-header {
  padding: 5px;
  text-align: center;
}
.row.all {
  margin-bottom: 0;
}
.consultando {
  margin-top: 40px;
}
.pacienteInfo {
  text-align: center;
}
.pacienteInfo .row {
  margin-top: 10px;
}
.bottom {
  position: fixed;
  bottom: 0;
}
.break {
  flex-basis: 100%;
  width: 0;
}
</style>
