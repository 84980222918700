<template>
  <div class="row no-margin-bottom">
    <div class="col s12 center-align container">
      <h1 class="show-on-small hide-on-med-and-up title-disclaimer-providencia-sm">Para ver tus resultados selecciona o has click sobre la fecha de tu atención</h1>
      <h1 class="hide-on-small-only show-on-medium-and-up title-disclaimer-providencia-mdplus">Para ver tus resultados selecciona o has click sobre la fecha de tu atención</h1>
    </div>

    <div class="col s12 xl6">
      <div class="row no-margin-bottom hoverable">
        <div class="col s12 center-align">
          <a @click="clickAntesDe" href="#!" target="self">
            <img width="100%" alt="Antes del 07 de mayo" @click="clickAntesDe" src="@/assets/img/antes_de_20220507.png" />
          </a>
        </div>
        <div class="col s12 center-align show-on-small hide-on-med-and-up">
          <button
            class="
              btn-disclaimer-providencia
              waves-effect waves-light
              btn-flat pulse
              modal-trigger
            "
            @click="clickAntesDe"
            >ANTES</button
          >
          <h1 class="title-disclaimer-providencia-sm">del 07 de mayo</h1>
        </div>
        <div class="col s12 center-align hide-on-small-only show-on-medium-and-up">
          <button
            class="
              btn-disclaimer-providencia
              waves-effect waves-light
              btn-flat pulse
              modal-trigger
            "
            @click="clickAntesDe"
            >ANTES del 07 de mayo</button
          >
        </div>
      </div>
    </div>
    <div class="col s12 xl6">
      <div class="row no-margin-bottom hoverable">
        <div class="col s12 center-align">
          <a
            class="
              modal-close
            "
            target="_self">
            <img width="100%" alt="Después del 07 de mayo" @click="clickDespuesDe" src="@/assets/img/despues_de_20220507.png" />
          </a>
        </div>
        <div class="col s12 center-align show-on-small hide-on-med-and-up">
          <a
            class="
              btn-disclaimer-providencia
              waves-effect waves-light
              btn-flat pulse
              modal-close
            "
            @click="clickDespuesDe"
            target="_self"
            >DESPUÉS</a
          >
          <h1 class="title-disclaimer-providencia-sm">del 07 de mayo</h1>
        </div>
        <div class="col s12 center-align hide-on-small-only show-on-medium-and-up">
          <a
            class="
              btn-disclaimer-providencia
              waves-effect waves-light
              btn-flat pulse
              modal-close
            "
            @click="clickDespuesDe"
            target="_self"
            >DESPUÉS del 07 de mayo</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import M from "materialize-css";
export default {
  data() {
    return {
      perdisteLink:
        "https://www.redsalud.cl/nuestra-red/campanas/recuperacion-numero-de-orden-de-examen?nombreclinica=campanas",
    };
  },
  props: {
    modo: String,
  },
  computed: {
    modalId() {
      return "ordenPago" + this.modo + "Example";
    },
  },
  methods: {
    clickAntesDe() {
      this.$gtag.pageview({
        page_path:
          "/laboratorio/redirect_bioslis_providencia",
      });
      this.$datadogRum.startView({
        name: "/laboratorio/redirect_bioslis_providencia",
        service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
        version: process.env.VUE_APP_VERSION
      })

      this.$gtag.event("redirect_bioslis_providencia", {
        event_category: "laboratorio",
        event_label: "redirect_bioslis_providencia",
        value: 1,
      });

      this.$gtag.event('external_redirect', {
        'event_category': 'laboratorio',
        'event_label': 'derivado_a_examenesprovidencia',
        'value': 1
      });
      window.location.href = "https://examenesprovidencia.redsalud.cl";
      // M.toast({html: 'Será redirigido en una nueva ventana al portal para la clínica Providencia', classes:'rounded'});
      // window.open("https://examenesprovidencia.redsalud.cl");
    },
    clickDespuesDe() {
      this.$emit('continue');
    }
  },
  mounted() {
    var elems = document.querySelectorAll(".modal");
    M.Modal.init(elems);
  },
};
</script>

<style scoped>
.title-disclaimer-providencia-sm {
  color: #57b0ae;
  font-size: 1.5em;
  padding-top: 5px;
}
.title-disclaimer-providencia-mdplus {
  color: #57b0ae;
}
.btn-disclaimer-providencia {
  background-color: #57b0ae;
  border-radius: 3px;
  color: #fff;
  padding: 2px 5px;
  text-transform: uppercase;
  font-size: 20px;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
</style>