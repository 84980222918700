<template>
  <div>
    <div v-show="error" class="col s12 consultando center-align">
      <div v-if="errorMsg === 'No hay resultados'">
        <div class="row">
          <div class="col s12">
            <span class="card-title">No existen resultados para esa orden de pago y documento.</span>
          </div>
        </div>
        <div class="row">
          <div class="col s12 footer-content">
            <button class="custom-btn" @click="CerrarErrorYVolver">Volver</button>
          </div>
        </div>
      </div>

      <div v-if="errorMsg !== 'No hay resultados'" class="card teal">
        <div class="card-content white-text">
          <span class="card-title">Se ha producido un error</span>
          <p>{{errorMsg}}</p>
        </div>
        <div class="card-action">
          <a class="waves-effect waves-light btn-small pulse yellow lighten-2 green-text" @click="CerrarErrorYVolver">Reintente por favor</a>
        </div>
      </div>
    </div>

    <branch-selector v-show="searching && !error" @branchSelected="branchSelected"/>

    <div v-show="!error && branch.length > 0">
      <form v-show="searching" @submit.prevent="doSubmit">
        <div class="row">
          <div class="col s12 m5 input-field hoverable">
            <label class="active" for="tipo_documento_select" >Tipo de Documento</label>
            <select :class="[{'browser-default': isMobile}]" v-model="tipo_documento" id="tipo_documento_select" name="tipo_documento_select">
              <option value="" disabled selected>Elija tipo de documento</option>
              <option v-for="(option, key) in tipos_documento" :key="key" :value="key">{{option}}</option>
            </select>
          </div>

          <div v-show="tipo_documento === 0" class="col s12 m7 input-field hoverable">
            <label for="numDocRut">Número de Documento</label>
            <input ref="rut" id="numDocRut" name="numDocRut" type="text" v-model="numberDoc" required v-rut:live v-validate="'required|rut'"/>
            <span v-show="errors.has('numDocRut')" class="red-text"><b>Rut no es válido</b></span>
          </div>

          <div v-show="tipo_documento === 1" class="col s12 m7 input-field hoverable">
            <label for="numDocPasaporte">Número de Documento</label>
            <input id="numDocPasaporte" name="numDocPasaporte" type="text" v-model="numberDoc" required v-validate="'required'"/>
          </div>

          <div class="col s12">
            <div class="row no-padding-x valign-wrapper hoverable">
              <div class="col s12 m5 input-field">
                <label for="numberOrden">Número de Orden de Pago</label>
                <input id="numberOrden" name="numberOrden" type="number" v-model="numberOrden" required v-validate="'required'"/>
                <span v-show="errors.has('numberOrden')" class="red-text"><b>Este dato es obligatorio</b></span>
              </div>
              <div class="col s12 m7">
                <comprobante-pago modo="lab"/>
              </div>
            </div>
          </div>

          <div class="col s12 footer-content" v-if="!loading">
            <a
              id="submitButton"
              :class="[{ 'disabled': !formularioOK, 'pulse': formularioOK }, 'waves-effect', 'waves-light', 'btn', 'custom-btn']"
              @click="doSubmit"
            >
              Ver resultados
            </a>
          </div>

          <div v-if="loading" class="col s12 consultando">
            <span>Consultando...</span>
            <div class="progress">
              <div class="indeterminate"></div>
            </div>
          </div>
        </div>
      </form>
      <div v-show="!searching">
        <div class="row">
          <div class="col s12 pacienteInfo">
            <label for="paciente">Paciente</label><br />

            <div v-if="info.patient" class="row">
              <div  class="col s12 m6">
                {{ info.patient.FullName }}
              </div>
              <div class="col s12 m6" v-show="DocumentType === 'RUT'">
                {{ DocumentType }}: <span >{{ info.patient.DocumentNumber | rutFilter }}</span>
              </div>
              <div class="col s12 m6" v-show="DocumentType !== 'RUT'">
                {{ DocumentType }}: <span >{{ info.patient.DocumentNumber }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row all" v-if="info.all && info.all.length === 0">
          <div class="col s12 text-center">No hay resultados</div>
        </div>
        <div class="row all-header" v-if="info.all && info.all.length > 0">
          <div class="col show-on-small s10 hide-on-med-and-up">Descripción</div>
          <div class="col hide-on-small-only m3">Fecha</div>
          <div class="col hide-on-small-only m7">Descripción</div>
          <div class="col s2">Resultado</div>
        </div>
        <div class="row all" v-for="(item, key) in info.all" :key="key">
          <div class="col show-on-small s10 hide-on-med-and-up">{{item['DESCRIPCION'][0].length > 0 ? item['DESCRIPCION'][0]:"(sin descripción)"}}<br/>{{item['FECHAENTREGA'][0]}}</div>
          <div class="col hide-on-small-only m3">{{item['FECHAENTREGA'][0]}}</div>
          <div class="col hide-on-small-only m7">{{item['DESCRIPCION'][0].length > 0 ? item['DESCRIPCION'][0]:"(sin descripción)"}}</div>
          <div class="col s2">
            <a v-show="item['IDEXAMEN'][0].length > 0" class="waves-effect waves-light btn-flat" @click="unlockDocument(key)"><i class="material-icons green-text">file_download</i></a>
            <div class="progress" v-show="item['DOWNLOADING']">
              <div class="indeterminate"></div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col s12 footer-content">
            <button class="custom-btn" @click="Volver">Volver</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import M from 'materialize-css';
import BranchSelector from './BranchSelector'
import ComprobantePago from './ComprobantePago'

const APIKEY = 'UMVyMXPIj0sz2NAFiTVhtc8q58D023Mb';

export default {
  name: "LabLanding",
  components: {
    BranchSelector,
    ComprobantePago
  },

  data() {
    return {
      branch: '',
      switchServer: false,
      tipos_documento: ['Cédula de identidad', 'Pasaporte'],
      tipo_documento: 0,
      env: '',
      error: false,
      errorMsg: '',
      loading: false,
      searching: true,
      numberOrden: process.env.VUE_APP_DEFAULT_NUMBER_ORDEN,
      numberDoc: process.env.VUE_APP_DEFAULT_NUMBER_DOC,
      rutFeedback: '',
      submitted: false,
      info: {},
      target: process.env.VUE_APP_API_TARGET,
    };
  },
  computed: {
    DocumentType() {
      if (this?.info?.patient?.DocumentType !== "NationalId") {
        return "Pasaporte";
      }
      return "RUT";
    },
    formularioOK() {
      return (
          this.errors.items.length === 0 ||
          this.tipo_documento === 1 && this.errors.has('numDocRut')
        )
        && this.numberDoc.length * this.numberOrden.length > 0;
    },
  },
  methods: {
    branchSelected (branch) {
      this.branch = branch;
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')

      // Do stuff with the received token.
      return token && token.length > 0;
    },
    doSubmit: function () {
      if (!this.recaptcha()){
        return;
      }

      this.loading = true;
      this.info = {};
      this.error = false;
      this.errorMsg = '';
      this.$gtag.pageview({ page_path: '/laboratorio/buscar' });
      this.$datadogRum.startView({
        name: "/laboratorio/buscar",
        service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
        version: process.env.VUE_APP_VERSION
      })

      this.$gtag.event('items_search', {
        'event_category': 'laboratorio',
        'event_label': 'request',
        'value': 1
      });

      axios
        .post(
          `${this.target}/lab`,
          {
            num_doc: this.numberDoc,
            tipo_doc: this.tipo_documento+"",
            magic_number: this.numberOrden
          },
          {
            headers: {
              "content-type": "application/json",
              "x-apikey": process.env.NODE_ENV === 'production' ? APIKEY : undefined
            },
          }
        )
        .then((response) => {
          this.loading = false;
          this.$emit('toggleTitle', "Resultados de exámenes de laboratorio");
          this.$emit('toggleShowSelectTipoExamen', false);

          if (response.status == 204 || 'msg' in response.data) {
            this.info.all = [];
            this.error = true;
            this.errorMsg = response.data.msg || "No hay resultados";
            this.$gtag.pageview({ page_path: '/laboratorio/sin-resultados' });
            this.$datadogRum.startView({
              name: "/laboratorio/sin-resultados",
              service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
              version: process.env.VUE_APP_VERSION
            })

          } else {
            this.searching = false;
            this.info = response.data;
            this.$gtag.pageview({ page_path: '/laboratorio/listado' });
            this.$datadogRum.startView({
              name: "/laboratorio/listado",
              service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
              version: process.env.VUE_APP_VERSION
            })
          }
        })
        .catch((error) => {
          this.$gtag.pageview({ page_path: '/laboratorio/error' });
          this.$datadogRum.startView({
            name: "/laboratorio/error",
            service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
            version: process.env.VUE_APP_VERSION
          })

          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            console.log(error.message);
            this.loading = false;
            this.error = true;
            this.errorMsg = error.message;
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        });
    },
    unlockDocument: function (key) {
      this.$set(this?.info?.all[key], 'DOWNLOADING', true);
      this.$gtag.pageview({ page_path: '/laboratorio/ver' });
      this.$datadogRum.startView({
        name: "/laboratorio/ver",
        service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
        version: process.env.VUE_APP_VERSION
      })

      axios
        .post(
          `${this.target}/pdfExamen`,
          {
            num_doc: this.numberDoc,
            tipo_doc: this.tipo_documento+"",
            magic_number: this.numberOrden,
            idExamen: this?.info?.all[key]['IDEXAMEN'][0],
            description: this?.info?.all[key]['DESCRIPCION'][0]
          },
          {
            headers: {
              "x-apikey": process.env.NODE_ENV === 'production' ? APIKEY : undefined
            },
          }
        )
        .then((response) => {
          this.$gtag.event('item_downloaded', {
            'event_category': 'laboratorio',
            'event_label': 'success',
            'value': 1
          });
          if(response.data.error) {
            this.$set(this?.info?.all[key], 'DOWNLOADING', false);
            M.toast({html: `No se puede descargar el archivo: **${response.data.msg}**`, classes:'rounded'});
          } else {
            // const url = window.URL.createObjectURL();
            const linkSource = `data:application/pdf;base64,${response.data}`;
            const link = document.createElement('a');
            link.href = linkSource;
            // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
            var filename = `${(this.numberDoc).replace(/\./g, '')}___${this?.info?.all[key]['DESCRIPCION'][0]}`
              .trim()
              .replace(/\/|\s|\.|,/g, '_')
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase();
            link.setAttribute('download', filename + '.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.$set(this?.info?.all[key], 'DOWNLOADING', false);
            M.toast({html: 'Se ha descargado el archivo', classes:'rounded'});
          }
        })
        .catch((error) => {
          this.$gtag.event('item_downloaded', {
            'event_category': 'laboratorio',
            'event_label': 'failure',
            'value': 1
          });

          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            console.log(error.message);
            this.loading = false;
            this.error = true;
            this.errorMsg = error.message;
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        });
    },
    CerrarErrorYVolver () {
      this.error = false;
      this.Volver();
    },
    Volver () {
      this.searching = true;
      this.$emit('toggleShowSelectTipoExamen', true);
      this.$emit('toggleTitle', "Ver mis exámenes");
      M.updateTextFields();
    }
  },
  mounted() {
    // M.AutoInit();
    M.updateTextFields();

    this.env = process.env.NODE_ENV;
  },
  watch: {
    tipo_documento(newVal) {
      if(newVal === 1) {
        /*
         * hemos elegido pasaporte,
         * sacar todo formato de numberDoc
         */
        this.numberDoc = this.numberDoc.replace(/-|\.|,/g, '');
      } else {
        setTimeout(() => {
          this.$refs.rut.focus();
          this.numberDoc = this.$options.filters.rutFilter(this.numberDoc);
        }, 10);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.all:nth-child(odd) {background-color: #e7e9e5;}
.all:nth-child(even) {background-color: #f2fff2;}
.all, .all-header {
  padding: 5px;
  text-align: center;
}
.row.all {
  margin-bottom: 0;
}
.consultando {
  margin-top: 40px;
}
.pacienteInfo {
  text-align: center;
}
.pacienteInfo .row{
  margin-top: 10px;
}
.bottom {
  position: fixed;
  bottom: 0;
}
.break {
  flex-basis: 100%;
  width: 0;
}

</style>
