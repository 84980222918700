<template>
  <div id="app">
    <header>
      <a href="https://www.redsalud.cl">
        <img class="logo-redsalud" alt="Logo Redsalud" src="./assets/img/logo-redsalud.png">
      </a>
    </header>

    <main>
      <div class="container center-align">
        <h1>{{ msg }}</h1>
      </div>

      <section>
        <div class="container-content form-exams">
          <div v-show="showSelectTipoExamen" class="select-tipo-examen center-align">
            <h5>Selecciona el tipo de examen</h5>

            <label class="custom-radio" for="radio-imagenes">
              <input name="tipoExamen" v-model="tipoExamen" type="radio" id="radio-imagenes" value="img" />
              <span class="title">Imágenes</span>
            </label>
            <label class="custom-radio" for="radio-lab">
              <input name="tipoExamen" v-model="tipoExamen" type="radio" id="radio-lab" value="lab" />
              <span class="title">Laboratorio</span>
            </label>
          </div>
          <exam-landing v-show="tipoExamen === 'img'" @toggleShowSelectTipoExamen='toggleShowSelectTipoExamen' @toggleTitle='toggleTitle'/>
          <lab-landing v-show="tipoExamen === 'lab'" @toggleShowSelectTipoExamen='toggleShowSelectTipoExamen' @toggleTitle='toggleTitle'/>

        </div>
      </section>
      <div class="version">{{version}}</div>
    </main>

    <footer class="page-footer">
      <div class="container" v-show="tipoExamen === 'img'"/>
    </footer>

  </div>
</template>

<script>
import LabLanding from './components/LabLanding.vue'
import ExamLanding from './components/ExamLanding.vue'
import mixin from "./mixins";

export default {
  name: 'App',
  components: {
    LabLanding,
    ExamLanding
  },
  mixins: [mixin],
  data () {
    return {
      version: process.env.VUE_APP_VERSION,
      msg: "Ver mis exámenes",
      showSelectTipoExamen: true,
      tipoExamen: ''
    }
  },
  methods: {
    toggleShowSelectTipoExamen (value) {
      this.showSelectTipoExamen = value;
    },
    toggleTitle (newMsg) {
      this.msg = newMsg;
    }
  },
  watch: {
    tipoExamen(newVal) {
      if(newVal === 'img') {
        this.$gtag.pageview({ page_path: '/examenes/home' });
        this.$datadogRum.startView({
          name: '/examenes/home',
          service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
          version: process.env.VUE_APP_VERSION
        })
      }
      if(newVal === 'lab') {
        this.$gtag.pageview({ page_path: '/laboratorio/home' });
        this.$datadogRum.startView({
          name: '/laboratorio/home',
          service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
          version: process.env.VUE_APP_VERSION
        })

      }
    }
  }
}
</script>

<style lang="css">
  @import "./assets/css/fonts.css";
  @import "./assets/css/style.css";
</style>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
#app header{
  text-align: center;
}

#app header{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
}

.logo-redsalud {
    width: 176px;
    height: 48px;
}

#app .container h1 {
  margin: 0;
}
.select-tipo-examen {
  margin-bottom: 50px;
}
.hoverable {
  padding: 15px;
}
.row.no-padding-x {
  padding-left: 0px;
  padding-right: 0px;
}
.version {
  text-align: center;
  color: lightgray;
}

</style>
